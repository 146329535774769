<script>
import QrScanner from 'qr-scanner';
import Modal from "./modal.vue";

export default {
  name: "timesheet",
  components: {Modal},
  methods: {
    startScan() {
      this.scanning = true;
      this.$nextTick(() => this.scanner.start());
      setTimeout(() => this.stopScan(), 1000 * 10);
    },
    stopScan() {
      this.scanning = false;
      this.scanner.stop();
    },
    async onScan(data) {
      this.code = data;
      this.stopScan();
    },
    async load() {
      try{
        this.loading = true;
        let response = (await axios.get(import.meta.env.VITE_BASE_API + '/entries', {params: {token : localStorage['api_key']}})).data;
        this.last_access = response.last_access;
        this.last_exit = response.last_exit;
      }catch(e){}
      this.loading = false;
    },
    reload() {
      window.location.reload();
    },
    async weather() {
      this.weather_data = (await axios.get(`https://api.openweathermap.org/data/3.0/onecall?lat=45.5199&lon=9.4697&exclude=current,minutely,hourly,alerts&lang=it&units=metric&appid=${import.meta.env.VITE_OPENWEATHER_KEY}`)).data.daily
    }
  },
  data: () => ({
    date: null,
    scanning: false,
    scanner: null,
    last_access: [],
    last_exit: [],
    error: '',
    code: null,
    loading: false,
    sound: {
      success: null,
      failure: null
    },
    weather_data: {},
    current_entry: {
      direction: null,
      name: null
    },
  }),
  beforeMount() {
    this.date = new Date();
  },
  mounted() {
    this.load();
    setInterval(() => this.date = new Date(), 1000);
    setTimeout(() => this.reload(), 60 * 30 * 1000);
    this.$nextTick(() => this.scanner = new QrScanner(this.$refs.video, ({data}) => this.onScan(data), {preferredCamera: 'user'}));
    this.sound.success = new Audio('/timesheet-success.mp3');
    this.sound.failure = new Audio('/timesheet-failure.mp3');
    window._pusher.bind('timesheet-update', call => {
      this.last_access = call.message.original.last_access;
      this.last_exit = call.message.original.last_exit;
    });
    this.weather();
  },

  watch: {
    code: async function () {
      if (this.code) {
        let c = this.code;
        this.code = null;
        try {
          let response = (await axios.post(import.meta.env.VITE_BASE_API + '/scan', {
            code: c,
            token: localStorage['api_key']
          })).data;
          this.current_entry = {
            direction: response.direction,
            name: response.name
          }
          this.sound.success.play();
          this.$refs.entry.open();
          setTimeout(() => this.$refs.entry.close(), 3 * 1000);
        } catch (e) {
          this.error = e.response.data.message;
          this.sound.failure.play();
        }
      }
    }
  }
}
</script>

<template>
  <div class="flex p-4 relative text-on-background h-full max-h-screen gap-4 w-full select-none">
    <div class="flex flex-col space-y-2 max-h-full w-1/2">
      <p class="text-center text-[1.5rem] capitalize" style="font-family: 'Chivo mono'">
        {{ date.toLocaleDateString('it-IT', {weekday: 'long'}) }}
        {{ date.getDay() }}
        {{ date.toLocaleDateString('it-IT', {month: 'long'}) }}
      </p>
      <div class="text-[6rem] text-white text-center flex items-center justify-center space-x-1.5 leading-none"
           style="font-family: 'Chivo mono'">
        <span>{{ date.getHours().toString().padStart(2, '0') }}</span>
        <i class="text-4xl fa-solid fa-colon"></i>
        <span>{{ date.getMinutes().toString().padStart(2, '0') }}</span>
        <i class="text-4xl fa-solid fa-colon"></i>
        <span>{{ date.getSeconds().toString().padStart(2, '0') }}</span>
      </div>

      <div class="grow">
        <div v-if="error" class="border border-red-700 bg-red-400 rounded-lg p-2">{{ error }}</div>
      </div>

      <div class="flex space-x-2">
        <button class="btn btn-secondary aspect-square text-lg" @click="reload"><i class="fa-solid fa-rotate-right"></i>
        </button>
        <button class="btn btn-primary  w-full py-2 text-lg" :class="{disabled: scanning || loading}" @click="startScan">Scansiona
          tessera
        </button>
      </div>

    </div>
    <div class="h-full w-1/2">
      <div class="w-full h-full" v-show="scanning">
        <video ref="video" class="rounded w-full h-full grow object-cover"></video>
      </div>
      <div v-if="!scanning" class="h-full max-h-lvh">
        <div class="h-1/2 flex flex-col">
          <h2 class="text-success uppercase text-xl font-medium text-center">Ora in ufficio</h2>
          <div class="flex flex-col gap-1.5 overflow-y-auto pr-1">
            <div v-for="employee in last_access"
                 class="flex justify-between items-center rounded-xl bg-primary text-white py-2 px-1.5 w-full">
              <span>{{ employee.name }} {{ employee.surname }}</span>
              <span> {{ employee.last_entry }}</span>
            </div>
            <p class="text-center mt-2 w-full" v-if="(!last_access || last_access.length === 0) && !loading">AH, Mattiniero?</p>
          </div>
        </div>
        <div class="h-1/2 flex flex-col">
          <h2 class="text-secondary uppercase text-xl font-medium text-center">Non in ufficio</h2>
          <div class="flex flex-col gap-1.5 overflow-y-auto pr-1">
            <div v-for="employee in last_exit"
                 class="flex justify-between items-center rounded-xl bg-background-accent text-white py-2 px-1.5 w-full">
              <span>{{ employee.name }} {{ employee.surname }}</span>
              <span v-if="employee.last_exit"> {{ employee.last_exit }}</span>
            </div>
            <p class="text-center mt-2 w-full" v-if="!last_exit || last_exit.length === 0"></p>
          </div>
        </div>
      </div>
    </div>
    <modal ref="entry" :close_on_backdrop="true">
      <div class="flex flex-col space-y-1.75 p-1">
        <span class="text-xl text-center">{{(current_entry.direction === -1) ? 'Buona Giornata' : 'Ciao'}} {{current_entry.name}}</span>
        <div class="flex items-center justify-center">
          <div class="rounded-full p-2 flex justify-center items-center text-center w-36 h-36" :class="(current_entry.direction === -1) ? 'bg-secondary' : 'bg-green'">
            <p class="text-4xl text-white text-center">
              <i v-if="current_entry.direction === -1" class="fa-solid fa-right-from-bracket"></i>
              <i v-else class="fa-solid fa-right-to-bracket"></i>
            </p>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
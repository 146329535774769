import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import axios from "axios";
import Pusher from "pusher-js";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
window.Pusher = Pusher;
window.PusherPushNotifications = PusherPushNotifications;
window._pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    channelAuthorization: {
        endpoint: import.meta.env.VITE_PUSHER_AUTH_ENDPOINT,
        headers:{
            "Autorization" : `Bearer ${localStorage['api_key']}`
        },
        params:{
            token: localStorage['api_key']
        }
    }
}).subscribe('private-timesheet');


window.axios = axios;
createApp(App).mount('#app');

if (localStorage['api_key']) {
    window.axios.defaults.params = {};
    window.axios.defaults.params['token'] = localStorage['api_key'];
}
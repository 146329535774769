<template>
  <div class="" v-if="show">
    <div class="fixed top-0 left-0 right-0 bottom-0 bg-smoke z-50" :class="backdrop_classes"
         @click.stop="on_backdrop_click" @keypress.esc="on_esc"></div>
    <div class="bg-background-light text-white fixed left-1/2 top-1/2 rounded-xl shadow p-3 modal-content transform -translate-x-1/2 -translate-y-1/2 z-50 max-h-[80%] overflow-auto"
         :class="modal_classes + ' ' + width_classes "
         :style="styles">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: {
    backdrop_classes: {
      type: String,
      default: ""
    },
    modal_classes: {
      type: String,
      default: ""
    },
    width_classes: {
      type: String,
      default: 'w-4/5 md:2-3/5 lg:w-1/3'
    },
    close_on_backdrop: {
      type: Boolean,
      default: true
    },
    close_on_esc: {
      type: Boolean,
      default: true
    },
    styles: {
      type: String,
      default: ""
    }
  },
  data: () => ({show: false}),
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    isClosed() {
      return !this.show;
    },
    on_backdrop_click() {
      if (this.close_on_backdrop) this.close();
    },
    on_esc() {
      if (this.close_on_esc) this.close();
    }
  },
}
</script>

<script>
export default {
    name: "register",
    computed: {
        isPendingRegistration() {
            return !!localStorage['api_key'];
        }
    },
    methods: {
        async register() {
            (await axios.get(`https://api.openweathermap.org/data/3.0/onecall?lat=45.5199&lon=9.4697&exclude=current,minutely,hourly,alerts&lang=it&units=metric&appid=${import.meta.env.VITE_OPENWEATHER_KEY}`)).data.daily
            let token = this.token();
            try {
                await axios.post(import.meta.env.VITE_BASE_API + '/register', {
                    name: this.name,
                    token: token
                });
                localStorage.setItem('api_key', token);
                localStorage.setItem('enabled', '0');
                window.location.reload();
            } catch (e) {
                console.error(e.response)
            }

        },
        async verify() {
            try {
                let response = (await axios.get(import.meta.env.VITE_BASE_API + '/verify', {
                  params: {
                    token: localStorage['api_key']
                  }
                })).data;
                if(response) localStorage.setItem('enabled', '1');
                window.location.reload();
            } catch (e) {
                console.error(e.response)
            }
        },
        token() {
            const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$';
            return Array.from(crypto.getRandomValues(new Uint32Array(64)))
                .map((x) => characters[x % characters.length])
                .join('')
        }
    },
    data: () => ({
        name: null
    })
}
</script>

<template>
    <div class="flex items-center justify-center h-full">
        <div class="bg-primary rounded p-4 w-2/3 max-w-[600px] space-y-4">
            <p class="text-xl text-center">Registrazione dispositivo</p>
            <template v-if="!isPendingRegistration">
                <div>
                    <label>Nome dispositivo</label>
                    <input type="text" class="bg-background border border-background" v-model="name">
                </div>
                <div class="flex justify-end">
                    <button class="btn bg-background hover:bg-background-accent tracking-wide" @click="register">Richiedi registrazione</button>
                </div>
            </template>
            <template v-else>
                <p class="text-center">In attesa di conferma dal server</p>
                <div class="flex justify-center">
                    <button class="btn bg-background hover:bg-background-accent tracking-wide" @click="verify">Verifica</button>
                </div>
            </template>
        </div>
    </div>
</template>
<script>

import Register from "./components/register.vue";
import Timesheet from "./components/timesheet.vue";

export default {
    components: {Timesheet, Register},
    beforeMount() {
        this.is_approved = localStorage['enabled'] && localStorage['enabled'] === '1';
    },
    data: () => ({
        is_approved: false
    })
}
</script>

<template>
    <register v-if="!is_approved"></register>
    <timesheet v-else></timesheet>
</template>